<template>
  <div>
    <!-- Filters -->
    <users-list-filters @updateStartDate="(val) => params.startDate = val"
      @updateEndDate="(val) => params.endDate = val" @updateSelectedProduct="(val) => (params.selectedProduct = val)" :productShow="true" />

    <div v-if="loading" class="text-center">
      {{ $t('Carregando...') }}
    </div>

    <b-row
      v-if="
        returnAllCryptoDepositsList &&
        returnAllCryptoDepositsList.statisticsDeposits &&
        items.length > 0 && !loading
      "
    >
      <b-col
        cols="12"
        md="6"
        lg="4"
        v-for="stat in returnAllCryptoDepositsList.statisticsDeposits"
        :key="`${stat._id.crypto}-${stat._id.status}`"
      >
        <withdraw-stats-card
          :title="$t(stat._id.type)"
          :subTitle="$t(stat._id.status)"
          :totalValueUSD="moneyFormat(stat.totalValueUSD)"
          :totalDiscountedUSDValue="moneyFormat(stat.totalDiscountedUSDValue)"
          :totalValueCrypto="stat.totalValueCrypto"
          :count="stat.count"
        ></withdraw-stats-card>
      </b-col>
    </b-row>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0" v-if="!loading">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Mostrar") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("entradas") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="this.$i18n.t('Buscar...')"
              /> -->
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        class="position-relative"
        striped
        responsive
        :items="items"
        :fields="fields"
        show-empty
        :empty-text="this.$i18n.t('Nenhum registro correspondente encontrado')"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="36"
                :src="renderImgAvatar(data.item.idUser.avatar)"
                :text="avatarText(data.item.idUser.fullname)"
              />
            </template>
            <div
              class="font-weight-bold d-block limitString"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary
              :title="data.item.idUser.fullname"
              variant="outline-primary"
            >
              {{ data.item.idUser.fullname }}
            </div>
            <div>
              <small class="text-muted">@{{ data.item.idUser.username }}</small>
            </div>
            <div>
              <small class="text-muted">{{ data.item.idUser.email }}</small>
            </div>
          </b-media>
        </template>

        <!-- Column: Crypto -->
        <template #cell(product)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.product === 'license' ? $t('Licença') : $t('Aporte') }}</span>
          </div>
        </template>

        <!-- Column: Crypto -->
        <template #cell(Crypto)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.crypto }}</span>
          </div>
        </template>

        <!-- Column: Balance -->
        <template #cell(valueUSD)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-primary">{{
              moneyFormat(data.item.value)
            }}</span>
          </div>         
        </template>

        <!-- Column: Balance -->
        <template #cell(discountedValue)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              moneyFormat(data.item.discountedValue)
            }}</span>
          </div>
        </template>

        <template #cell(valueBRL)="data">
          <div class="text-nowrap">
            <!-- <span class="align-text-top">{{
              data.item.valueBRL.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            }}</span> -->
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
          >
            {{ $t(`${data.item.status}`) }}
          </b-badge>
        </template>

        <!-- Column: Create Date -->
        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{$t('Criado em') + ': ' + format(new Date(data.item.createdAt), "dd/MM/yyyy HH:mm")}}</span>
          </div>
          <div class="text-nowrap" v-if="data.item.updatedAt">
            <span class="align-text-top">{{$t('Atualizado em') + ': ' + format(new Date(data.item.updatedAt), "dd/MM/yyyy HH:mm")}}</span>
          </div>
        </template>

        <!-- Column: Create Date -->
        <template #cell(viewProof)="data">
          <div class="text-nowrap">
            <b-button
              size="sm"
              variant="warning"
              @click="
                () => {
                  $bvModal.show('modal-view-proof');
                  imgProof = data.item.proof;
                  clientData = data.item.idUser;
                }
              "
              >Visualizar</b-button
            >
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal.modal-send-email
              @click="() => (usernameToDisable = data.item)"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">{{
                $t("Reenviar e-mail")
              }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal.modal-disable-user
              @click="() => (usernameToDisable = data.item)"
            >
              <feather-icon
                :icon="
                  data.item.status == 'active' ? 'UserXIcon' : 'UserCheckIcon'
                "
              />
              <span class="align-middle ml-50">{{
                data.item.status == "active"
                  ? $t("Desativar usuário")
                  : $t("Ativar usuário")
              }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("Mostrando") }} {{ entriesFrom }} {{ $t("a") }}
              {{ entriesTo }} {{ $t("de") }} {{ totalRows }}
              {{ $t("entradas") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import { mapGetters, mapActions } from "vuex";
import { format } from "date-fns";
import { debounce } from "debounce";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, min, max } from "@/libs/validations";
import Ripple from "vue-ripple-directive";
import WithdrawStatsCard from "./WithdrawStatsCard.vue";
import UsersListFilters from "./UsersListFilters.vue";


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,

    WithdrawStatsCard,
    UsersListFilters,

    localize,

    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 0,
      currentPage: 1,
      entriesTo: 0,
      entriesFrom: 0,
      searchQuery: "",
      API_URL: process.env.VUE_APP_API_URL,
      usernameToDisable: "",
      reasonDisable: "",
      imgProof: null,
      clientData: {},
      loading: false,

      required,
      min,
      max,

      fields: [
        { key: "user", label: null },
        { key: "product", label: null },
        { key: "valueUSD", label: null },
        { key: "status", label: null },
        { key: "createdAt", label: null },
      ],
      items: [],
      params: {
        startDate: null,
        endDate: null,
        selectedProduct: null,
        page: this.currentPage,
        limit: this.perPage,
        search: "",
        sortby: "createdAt",
        sortdirection: "desc",
      },
      sortByFilter: null,
      sortByOptions: [],
      sortDirectionFilter: null,
      sortDirectionOptions: [],

      openModalDeposit: false,
    };
  },
  computed: {
    ...mapGetters(["returnLocale", "returnAllCryptoDepositsList"]),
  },
  mounted() {
    this.renderUsersList();

    this.fields[0].label = this.$i18n.t("Usuário");
    this.fields[1].label = this.$i18n.t("Produto");
    this.fields[2].label = this.$i18n.t("Valor");
    this.fields[3].label = this.$i18n.t("Status");
    this.fields[4].label = this.$i18n.t("Data");

    this.sortByOptions = [
      { label: this.$i18n.t("Data de cadastro"), value: "createdAt" },
      { label: this.$i18n.t("E-mail"), value: "email" },
      { label: this.$i18n.t("Nome completo"), value: "fullname" },
      { label: this.$i18n.t("Saldo"), value: "balance" },
      { label: this.$i18n.t("Status"), value: "status" },
      { label: this.$i18n.t("Usuário"), value: "username" },
    ];

    this.sortDirectionOptions = [
      { label: this.$i18n.t("Crescente"), value: "asc" },
      { label: this.$i18n.t("Decrescente"), value: "desc" },
    ];
  },
  watch: {
    returnAllCryptoDepositsList: function (oldVal, newVal) {
      this.totalRows = oldVal.total;
      this.entriesTo =
        oldVal.perPage * oldVal.currentPage > oldVal.total
          ? oldVal.total
          : oldVal.perPage * oldVal.currentPage;
      this.entriesFrom =
        oldVal.perPage * oldVal.currentPage + 1 - oldVal.perPage;
      this.items = oldVal.depositsCP;
    },
    perPage: function (newVal) {
      this.params.limit = newVal;
      this.renderUsersList();
    },
    currentPage: function (newVal) {
      this.params.page = newVal;
      this.renderUsersList();
    },
    searchQuery: debounce(function (newVal) {
      this.params.search = newVal;
      this.renderUsersList();
    }, 500),
    statusFilter: function (newVal) {
      this.params.status = newVal;
      this.renderUsersList();
    },
    sortByFilter: function (newVal) {
      this.params.sortby = newVal;
      this.renderUsersList();
    },
    sortDirectionFilter: function (newVal) {
      this.params.sortdirection = newVal;
      this.renderUsersList();
    },
    "params.startDate": function (newVal) {
      this.renderUsersList();
    },
    "params.endDate": function (newVal) {
      this.renderUsersList();
    },
    "params.selectedProduct": function (newVal) {
      this.renderUsersList();
    },
  },
  methods: {
    ...mapActions(["usersList"]),
    renderImgAvatar(img) {
      if (img)
        return img ? process.env.VUE_APP_API_URL + "/avatar/" + img : null;
    },
    renderUsersList() {
      this.loading = true;
      this.totalRows = 0;
      this.entriesTo = 0;
      this.entriesFrom = 0;
      this.items = [];
      this.$store
        .dispatch("allCryptoDepositsList", this.params) 
        .then((resp) => {
          this.loading = false;
        })
        .catch((resp) => {
          this.loading = false;
        });
    }, 

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.disableUser.validate().then((success) => {
        if (success) {
          this.handleSubmit();
        }
      });
    },
  },
  setup() {
    format;

    const isAddNewUserSidebarActive = ref(false);

    const resolveUserStatusVariant = (status) => {
      if (status === "completed") return "success";
      if (status === "pending") return "warning";
      if (status === "canceled") return "danger";
      if (status === "payment released") return "info";
      return "info";
    };

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      // Utilis
      avatarText,
      format,

      // UI
      resolveUserStatusVariant,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.limitString {
  white-space: nowrap;
  max-width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;

  .teste {
    background-color: #0101c7;
  }
}
</style>
